import * as $ from  'jquery';
import '../../vendors/lightGallery/dist/js/lightgallery-all.min';
/**
 *  TO EXTEND APP
 * @param parentfn , get all  private functions
 */
export default function (parentfn) {
    const self = this;  // main (public)
    const setup =() => {
        const old_commons = parentfn._commons;
        // overwrite private functions from parent like parentfn._install =()=>{ ... }
        parentfn._commons =()=>{
            if(!App.utils.isMobile()) {
                $('img.zoom').each(function() {
                    var img;
                    img = $(this).attr('src');
                    return $(this).wrap("<div class='image-zoom' style='transition: transform .2s'><a href='" + img + "'></a></div>").css("cursor", "zoom-in").parent().parent().hover((function() {
                        return $(this).css('transform', 'scale(1.05)');
                    }), function() {
                        return $(this).css('transform', 'scale(1)');
                    }).lightGallery({download: false});
                });
            }
            // Execute old original commons
            old_commons();
        };
    };
    //Auto execute private overwrites
    if (parentfn){ setup(); }
    // Extend and overwrite functions like start, init etc.. this.start=()=>{...} = (public  App.start())

}